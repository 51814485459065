import React, { useState, useEffect } from 'react';

import './lab-testing-card.scss';

const LabTestingCard = (props) => {
  const { name, img, details } = props;

  // useEffect(() => {
  //   const productContainer = document.querySelector('.product-container');
  //   const callback = (entries, observer) => {
  //     entries.forEach((entry) => {
  //       entry.target.classList.toggle(
  //         'product-container-animation',
  //         entry.isIntersecting
  //       );
  //     });
  //   };

  //   const myObserver = new IntersectionObserver(callback, {});
  //   myObserver.observe(productContainer);
  // }, []);

  return (
    <div className="lab-testing-card text-center text-light product-container">
      <div className="product-slide-in" >
        <img src={img} className="lab-image-margin" />
        <h3 className="name my-3">{name}</h3>
        <p className="text-justify">{details}</p>
      </div>
    </div>
    
  );
};
export default LabTestingCard;
