import React, { useState, useEffect } from 'react';

import bar1 from '../images/bar1.png';
import stone2 from '../images/stone2.png';
import techno1 from '../images/techno1-img.jpg';
import techno2 from '../images/techno2.jpg';
import techno3 from '../images/techno3.jpg';
import techno4 from '../images/techno4.jpg';
import RTBG from '../images/Refining-Technique-bg.jpg';

import FeedbackForm from '../components/FeedbackForm/FeedbackForm';

import './technology.scss';

import Page from '../components/layout/Page';

import Banner from '../components/Banner/Banner';
import ImgBgContainer from '../components/ImgBgContainer';

import { labTestings } from './technology.json';

import LabTestingCard from '../components/LabTestingCard.js/LabTestingCard';

const Technology = () => {

  useEffect(() => {
    const productContainer = document.querySelector('.lab-testing-card-container');
    const callback = (entries, observer) => {
      entries.forEach((entry) => {
        entry.target.classList.toggle(
          'lab-testing-card-container-animation',
          entry.isIntersecting
        );
      });
    };

    const myObserver = new IntersectionObserver(callback, {});
    myObserver.observe(productContainer);
  }, []);

  return (
    <Page name="Technology">
      <Banner bgImg="/images/technology.jpg" title="TECHNOLOGY" />
      <section className="ribbon-bg">
        <div className="container p-5">
          <h2 className="section-title">REFINING TECHNIQUE</h2>

          <div className="row mt-5">
            <div className="col-md-6">
              <h3>AQUA REGIA</h3>
              <p className="paragraph-aqua">
                In Aqua Regia, 99.9% pure metallic gold is obtained. This
                process can be employed on a large scale. A primary step of the
                process is to dissolve the precious metals and some impurities.
                Aqua Regia combines nitric acid and hydrochloric acid so that
                some silver remains in solution and some forms a silver chloride
                precipitate. Silver content must also be taken into
                consideration. There is no doubt that silver chloride formation
                negatively affects gold dissolution. As soon as the dissolution
                is complete, the solution and precipitate are separated by
                filtration. A reducing agent such as ferrous sulphate, sulfur
                dioxide, or sodium bisulphate is used to reduce the gold in the
                solution. High-purity gold powder is then obtained that can be
                melted. The platinum group metals remain in solution when gold
                precipitates, so they must be recovered in another way.
              </p>
            </div>
            <div className="col-md-6 text-center">
              <img
                className="w-75 d-block aqua-regia-img"
                src="/images/aqua-regia.png"
              />
            </div>
          </div>
        </div>
      </section>

      <ImgBgContainer bgImg="/images/surface-dark.jpg" overlay="dark">
        <div className="container p-5">
          <h2 className="section-title text-gold">LABORATORY TESTING</h2>

          <div className="row my-5 lab-testing-card-container">
            {labTestings.map((testing) => (
              <div key={testing.name} className="col-md-4 slide-in">
                <LabTestingCard {...testing} />
              </div>
            ))}
          </div>
        </div>
      </ImgBgContainer>
    </Page>
  );
};


export default Technology;
